import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import env from '../../env';
import {setEnterPath} from "../utils/base";

const routes = [
  {
    name: '登录',
    path: '/user/login',
    component: () =>
      import("../views/user/login.vue"),
  },
  {
    name: '绑定',
    path: '/user/bind',
    component: () =>
      import("../views/user/bind.vue"),
  },
  {
    name: '加载微信配置',
    path: '/user/load_wx_config',
    component: () =>
      import("../views/user/load_wx_config.vue"),
  },
  {
    name: "用户资料",
    path: "/",
    component: () =>
      import("../views/home"),
  },
  {
    name: '订单详情',
    path: '/home/detail/:strItem',
    component: () =>
      import("../views/home/detail.vue"),
  },
  {
    name: "订单",
    path: "/order/index",
    component: () =>
      import("../views/order/index.vue"),
  },
  {
    name: "聊天",
    path: "/chat/index",
    component: () =>
        import("../views/chat/index.vue"),
  },
  {
    name: "素材",
    path: "/material/index",
    component: () =>
        import("../views/material/index.vue"),
  },
  {
    name: "清除缓存",
    path: "/home/clear_cache",
    component: () =>
        import("../views/home/clear_cache.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  // test
  if (env.is_local()) {
    localStorage.setItem('token', '3cc942d5c9009f1c53864933891b20f7');
    localStorage.setItem('qy_out_user_id', 'wmf7WCBgAARd5tZpMHzokNEeSUxn-Stg');
    localStorage.setItem("loadWxConfig", 1);
  }

  console.log('to', to);
  console.log('from', from);

  var token = localStorage.getItem('token');
  if (token) {
    if (to.path == '/user/login') {
      next('/')
    } else {
      next()
    }
  } else {
    if (to.path == '/user/login') {
      next();
    } else {
      setEnterPath(to.path);
      next('/user/login');
    }
  }
});

export default router;
