let env = {
    env: '',
    baseUrl: '',
    type: '',
    online_url: '',
    corpid: '',
    agentId: '',
    is_local: function() {
        return this.env == 'local'
    },
    is_dev: function() {
        return this.env == 'dev'
    },
    is_test: function() {
        return this.env == 'test'
    },
    is_prod: function() {
        return this.env == 'prod'
    },
    init: function() {
        let url = window.location.href
        if (url.indexOf('localhost') >= 0) {
            this.env = 'local'
            this.baseUrl = 'https://testv1.vipzhhr.com';
            this.type = 'zh_test';
            this.online_url = 'https://testv1ewx.vipzhhr.com';
            this.corpid = 'ww2158976b1c4916a9';
            this.agentId = '1000003';
        } else if (url.indexOf('testv1ewx') >= 0) {
            this.env = 'dev'
            this.baseUrl = 'https://testv1.vipzhhr.com'
            this.type = 'zh_test';
            this.online_url = 'https://testv1ewx.vipzhhr.com';
            this.corpid = 'ww2158976b1c4916a9';
            this.agentId = '1000003';
        } else {
            this.env = 'prod'
            this.baseUrl = 'https://www.vipzhhr.com';
            this.type = 'zh';
            this.online_url = 'https://ewx.vipzhhr.com';
            this.corpid = 'ww9b22e9d4f793c8da';
            this.agentId = '1000015';
        }
        console.log(url);
        console.log(this.env);
        console.log(this.baseUrl);
        console.log(this.type);
        console.log(this.online_url);
        console.log(env.is_local(), env.is_dev(), env.is_test(), env.is_prod())
    },
}

// 环境初始化
env.init()

export default env
